.container
{
  text-align: center;
  margin-top: 50px;
  font-size: xx-large;
}

.about
{
  margin: 10px;
  text-align: center;
}

.contact
{
    text-align: center;
}
